import * as React from 'react';
import {graphql, Link} from 'gatsby';
import {Grid, List, ListItem, ListItemAvatar, ListItemText, Paper} from "@material-ui/core";
import {Layout} from "../common/Layout";
import {SEO} from "../components/seo";
import {GameDetailBlock} from "../components/GameDetailBlock";
import {makeStyles} from "@material-ui/core/styles";
import {SetIcon} from "../features/sets/SetIcon";
import {useUIState} from "../app/uiContext";

// Query to be run on build, passes resulting JSON as 'data' prop
export const query = graphql`
  query($urlTitle: String!) {
    directusGame(url_title: { eq: $urlTitle }) {
      directusId
      name
      url_title
      publisher
      publication_date
      in_print
      languages
      website
      description
      fields {
        logoUrl
          }
      series {
        directusId
        sort
        name
        sets {
          directusId
          status
          sort
          name
          url_title
          release_date
          svg_icon
          logo
          logo_url
          fields {
            totalCards
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
    secondary: {
        minHeight: theme.spacing(2.5),
    },
}));

const SetItem = ({baseUrl, gameUrlTitle, set, setIconProps}) => {
    const { userLocale } = useUIState();
    const classes = useStyles();
    const dt = set.release_date && new Date(set.release_date);
    const setReleaseDate = dt && dt.getTime && !isNaN(dt.getTime()) ? new Intl.DateTimeFormat(userLocale, {year: `numeric`, month: `long`}).format(dt) : ``;
    return (
        <ListItem button component={Link} divider to={`${baseUrl}/${set.url_title}/`}>
            <ListItemAvatar className="mr-4">
                <SetIcon altText={set.name} fileId={set.logo} gameUrlTitle={gameUrlTitle} logoUrl={set.logo_url} svgIcon={set.svg_icon} {...setIconProps} />
            </ListItemAvatar>
            <ListItemText primary={set.name} secondary={setReleaseDate} secondaryTypographyProps={{className: classes.secondary}} />
        </ListItem>
    )
}

const SeriesItem = ({baseUrl, gameUrlTitle, series, setProps}) => {
    if (!series.sets || !series.sets.length > 0) {
        return null;
    }
    // Sort by "sort" field is already done automatically
    if (series.sets[0].release_date && series.sets[0].release_date.length > 0 && !series.sets[0].sort) {
        series.sets.sort((a,b) => a.release_date < b.release_date ? 1 : a.release_date > b.release_date ? -1 : 0);
    }
    return (
        <Paper className="mt-4">
            <h3 className="p-4 text-lg">{series.name !== `-` ? series.name : `First Edition`}</h3>
            <List component="div" disablePadding>
                {series.sets.map(set => (
                    <SetItem baseUrl={baseUrl} gameUrlTitle={gameUrlTitle} key={set.directusId} set={set} {...setProps} />
                ))}
            </List>
        </Paper>
    )
}

// Basic game component
const GameTemplate = ({ data }) => {
    const game = data.directusGame;
    const hasSeries = Array.isArray(game.series) && game.series.length > 0;
    let setProps = undefined;
    if (game.directusId === 158) { // Special white background for icons in these games: Pokemon
        setProps = {
            setIconProps: {
                imgProps: {
                    style: {
                        backgroundColor: `white`
                    }
                }
            }
        };
    }
    // Sort by "sort" field is already done automatically
    if (hasSeries && !game.series[0].sort) {
        game.series.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
    }
    return hasSeries ? (
        <Layout>
            <SEO description={`Trade ${game.name}`} keywords={[game.name, `ccg`, `collectible`, `card`, `game`, `trade`]} title={game.name} />
            <section className="">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <GameDetailBlock game={game} />
                        {game.series.map(series => (
                            <SeriesItem baseUrl={`/games/${game.url_title}`} gameUrlTitle={game.url_title} key={series.name} series={series} setProps={setProps} />
                        ))}
                    </Grid>
                </Grid>
            </section>
        </Layout>
    ) :  (
        <p className={`m-4 text-xl`}>No results</p>
    )
};

export default GameTemplate;
