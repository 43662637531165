import * as React from "react";
import { Avatar } from "@material-ui/core";

/*
// TODO: Can't seem to get dynamic import to work with SSR. Revisit another time.
const SvgIcon = ({ className, gameUrlTitle, svgIcon, ...rest }) => {
    const ImportedIconRef = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    console.log(svgIcon);
    React.useEffect(() => {
        console.log(svgIcon);
        setLoading(true);
        const importIcon = async () => {
            try {
                const iconComponent = svgIcon.split(/(\d)/).map((a)=> (a[0] && a[0].toUpperCase()+a.substring(1)) || a).join(``);
                import(`../images/games/${gameUrlTitle}/set-icons/${iconComponent}`).then((m) => {
                    ImportedIconRef.current = m.default;
                })
                //ImportedIconRef.current = await import(`../images/games/${gameUrlTitle}/set-icons/${iconComponent}`).default;
            } catch (err) {
                // Your own error handling logic, throwing error for the sake of simplicity
                throw err;
            } finally {
                setLoading(false);
            }
        };
        importIcon();
    }, [gameUrlTitle, svgIcon]);


    console.log({loading, ImportedIconRef});
    if (!loading && ImportedIconRef.current) {
        const { current: ImportedIcon } = ImportedIconRef;
        console.log(ImportedIcon);
        return <ImportedIcon className={`fill-current text-white w-auto`} />;
    }

    return null;
};

*/

export const SvgIcon = ({
    alt,
    className,
    gameUrlTitle,
    imgProps,
    svgIcon,
    ...rest
}) => {
    return (
        <Avatar
            alt={alt}
            imgProps={imgProps}
            src={`/images/games/${gameUrlTitle}/set-icons/${svgIcon}.svg`}
            variant="square"
        />
    );
};
